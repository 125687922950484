<template>
  <v-dialog v-model="modalData.dialog" width="1450px" persistent scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        New employee
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn
              @click="handleCloseModalForm"
              style="float: right; cursor: pointer"
              icon
              color="#26223c"
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <!-- <v-col cols="12" sm="3" md="3">
              <v-select v-model="selectedLocale" :items="languages">
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col> -->
            <v-col cols="12" sm="3" md="3">
              <v-select
                v-if="statuses.system"
                v-model="formModel.status"
                :items="statuses.companySettings.department"
                label="Status"
                item-text="value"
                item-value="key"
              />
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-container>
            <v-row>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  v-model="formModel.surname"
                  label="First name"
                  :rules="nameRules"
                  :id="dynamicID"
                  :error-messages="messages['surname']"
                  @keyup="messages['surname'] = ''"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  v-model="formModel.christian_name2"
                  label="Middle name"
                  :id="dynamicID"
                  :error-messages="messages['christian_name2']"
                  @keyup="messages['christian_name2'] = ''"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  v-model="formModel.christian_name1"
                  label="Last name"
                  :rules="nameRules"
                  :id="dynamicID"
                  :error-messages="messages['christian_name1']"
                  @keyup="messages['christian_name1'] = ''"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  v-model="formModel.email"
                  label="Email"
                  :rules="emailRules"
                  :id="dynamicID"
                  :error-messages="messages['email']"
                  @keyup="messages['email'] = ''"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  v-model="formModel.birth_name"
                  label="Birth name"
                  :id="dynamicID"
                  :error-messages="messages['birth_name']"
                  @keyup="messages['birth_name'] = ''"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  v-model="formModel.mother_name"
                  label="Mother name"
                  :id="dynamicID"
                  :error-messages="messages['mother_name']"
                  @keyup="messages['mother_name'] = ''"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-select
                  v-model="formModel.locale"
                  :items="languages"
                  item-text="name"
                  item-value="lang"
                  label="Language"
                  :rules="languageRules"
                  :id="dynamicID"
                ></v-select>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-checkbox
                  v-model="formModel.login"
                  label=" login"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  v-model="formModel.birth_place"
                  label="City of bith"
                  :id="dynamicID"
                  :error-messages="messages['birth_place']"
                  @keyup="messages['birth_place'] = ''"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <!-- <label for="datepicker-full-width">Date of birth</label>

                <b-form-datepicker
                  id="datepicker-birth_date"
                  v-model="formModel.birth_date"
                  start-weekday="1"
                  calendar-width="100%"
                  locale="en"
                  class="mb-2"
                ></b-form-datepicker>
                <small v-if="messages['birth_date']" style="color: red">
                  {{ messages["birth_date"] }}
                </small> -->

                <div>
                  <label for="example-input">Date of birth</label>
                  <b-input-group class="mb-3">
                    <b-form-input
                      id="example-input"
                      v-model="selectedDate"
                      type="text"
                      placeholder="YYYY-MM-DD"
                      autocomplete="off"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="selectedDate"
                        button-only
                        right
                        locale="en-US"
                        aria-controls="example-input"
                        @context="onContext"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="messages['birth_date']" style="color: red">
                    {{ messages["birth_date"] }}
                  </small>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  v-model="formModel.addresses.address"
                  label="Permanent address"
                  :id="dynamicID"
                  :error-messages="messages['address']"
                  @keyup="messages['address'] = ''"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  v-model="formModel.contact_data.phone_number"
                  label="Phone number"
                  :id="dynamicID"
                  :error-messages="messages['phone_number']"
                  @keyup="messages['phone_number'] = ''"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  v-model="formModel.identity_card_number"
                  label="Identity card number"
                  :id="dynamicID"
                  :error-messages="messages['identity_card_number']"
                  @keyup="messages['identity_card_number'] = ''"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  v-model="formModel.address_card_number"
                  label="Address card number"
                  :id="dynamicID"
                  :error-messages="messages['address_card_number']"
                  @keyup="messages['address_card_number'] = ''"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  v-model="formModel.vat_number"
                  label="Tax identifier"
                  :id="dynamicID"
                  :error-messages="messages['vat_number']"
                  @keyup="messages['vat_number'] = ''"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  v-model="formModel.insurance_number"
                  label="Social Sec. no."
                  :id="dynamicID"
                  :error-messages="messages['insurance_number']"
                  @keyup="messages['insurance_number'] = ''"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" sm="4" md="4">
                <label for="datepicker-full-width">Start of employment</label>

                <b-form-datepicker
                  id="datepicker-work-start"
                  v-model="formModel.work_start"
                  start-weekday="1"
                  calendar-width="100%"
                  locale="en"
                  class="mb-2"
                ></b-form-datepicker>
                <small v-if="messages['work_start']" style="color: red">
                  {{ messages["work_start"] }}
                </small>
              </v-col>

              <v-col cols="4" sm="4" md="4">
                <v-select
                  v-model="formModel.site_id"
                  :items="siteCollection"
                  label="Select Site"
                  item-text="name"
                  item-value="id"
                  :id="dynamicID"
                />
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-select
                  v-model="formModel.position_id"
                  :items="positionCollection"
                  label="Select Position"
                  item-text="name"
                  item-value="id"
                  :id="dynamicID"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" sm="4" md="4">
                <label for="datepicker-full-width">End of employment</label>

                <b-form-datepicker
                  id="datepicker-work-end"
                  v-model="formModel.work_end"
                  start-weekday="1"
                  calendar-width="100%"
                  locale="en"
                  class="mb-2"
                ></b-form-datepicker>
                <small v-if="messages['work_end']" style="color: red">
                  {{ messages["work_end"] }}
                </small>
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-select
                  v-model="formModel.department_id"
                  :items="departmentCollection"
                  label="Select Department"
                  item-text="name"
                  item-value="id"
                  :id="dynamicID"
                />
              </v-col>

              <v-col cols="4" sm="4" md="4">
                <v-select
                  v-model="formModel.education_id"
                  :items="educationCollection"
                  label="Select Education"
                  item-text="name"
                  item-value="id"
                  :id="dynamicID"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  v-model="formModel.comment"
                  label="Comment"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="blue darken-1"
          text
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar" :timeout="2000">
      Successfully saved
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { ENDPOINT, PERMISSION_TO } from "./Employees";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";

const TRANSLATED_ATRIBUTES = [];

export const initialFormData = () => ({
  id: null,
  status: 1,
  translations: [],
  locale: "hu",
  login: 1,
  contact_data: [{ phone_number: null }],
  email: null,
  surname: null,
  christian_name1: null,
  christian_name2: null,
  birth_place: null,
  birth_date: null,
  mother_name: null,
  vat_number: null,
  identity_card_number: null,
  address_card_number: null,
  insurance_number: null,
  addresses: [{ address: null }],
  position_id: null,
  education_id: null,
  work_start: null,
  work_end: null,
  attachments: null,
  department_id: null,
  site_id: null,
  portal: false,
  newsletter: false,
  comment: null,
});

export default {
  name: "EmployeeModalForm",
  props: [
    "modalData",
    "permissions",
    "departmentCollection",
    "siteCollection",
    "positionCollection",
    "educationCollection",
  ],
  mixins: [formModelMixins],
  data() {
    return {
      loader: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      permissionTo: PERMISSION_TO,
      endPoint: ENDPOINT,

      formValid: false,
      messages: {},
      nameRules: [
        (v) => !!v || "This field is required",
        (v) =>
          (!!v && v.length < 255) || "This field must be max 255 characters",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      selectedDate: null,
    };
  },
  computed: {
    ...mapGetters(["statuses"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id ? "Edit" : "New employee";
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getActiveLanguage();
        });
        if (value.editedId) {
          ApiService.get(ENDPOINT + value.editedId)
            .then(({ data }) => {
              data.surname = data.user.surname;
              data.christian_name1 = data.user.christian_name1;
              data.christian_name2 = data.user.christian_name2;
              data.fullName = data.surname + " " + data.christian_name1;
              if (data.christian_name2)
                data.fullName += " " + data.christian_name2;
              data.status = data.user.status;
              data.email = data.user.email;
              data.locale = data.user.locale;
              data.login = data.user.login;
              data.portal = data.user.portal;
              data.newsletter = data.user.newsletter;
              if (!data.addresses) data.addresses = [{ address: null }];
              if (!data.contact_data)
                data.contact_data = [{ phone_number: null }];
              this.formModel = Object.assign({}, data);
              this.selectedDate = this.formModel.birth_date;
              this.setTranslatedAttributes();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.selectedDate = this.formModel.birth_date;
          this.setTranslatedAttributes();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    // ...mapActions([FETCH_CURRENCY]),

    handleSaveModalForm() {
      let model = Object.assign({}, this.formModel);

      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        // this.modalData.loading = true;
        this.formModel.birth_date = this.selectedDate;
        /**Delete unused translations */
        if (TRANSLATED_ATRIBUTES.length > 0) {
          let translations = {};
          i18nService.languages.forEach((item) => {
            let langCode = item.lang;
            if (
              model.translations[langCode] &&
              model.translations[langCode].name != ""
            ) {
              translations[langCode] = Object.assign(
                {},
                model.translations[langCode]
              );
            }
          });
          if (Object.keys(translations).length != 0) {
            model.translations = translations;
          }
        }
        /**Delete unused translations */
        this.loader = true;
        if (model.id) {
          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              this.snackbar = true;
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(ENDPOINT, model)
            .then(({ data }) => {
              this.snackbar = true;

              this.$emit("saveModalForm", data);
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      // The following will be an empty string until a valid date is entered
      // console.log(ctx);
      this.formModel.birth_date = ctx.selectedYMD;
    },
  },

  mounted() {
    this.setTranslatedAttributes();
  },
};
</script>
