<template>
  <v-row justify="center">
    <v-dialog v-model="modalData.dialog" persistent scrollable width="1350px">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span class="text-h5">{{ modalData.fullName }}</span>
              <v-btn
                @click="handleCloseModalForm"
                style="float: right; cursor: pointer"
                icon
                color="#26223c"
              >
                <v-icon> mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="dates"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formattedDates"
                      label="Select range"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates"
                    :first-day-of-week="1"
                    range
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(dates)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-form v-model="formValid" ref="form">
                  <!-- height="300px" -->
                  <v-simple-table fixed-header height="650px" dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left" style="width: 100px">
                            Date
                            <br />
                            <br />
                            Total hours:
                          </th>
                          <th class="text-left">
                            Working hours <br /><br />{{ totalTimes[1] }}
                          </th>
                          <th class="text-left">
                            Holiday<br /><br />{{ totalTimes[3] }}
                          </th>
                          <th class="text-left">
                            Sick leave<br /><br />{{ totalTimes[4] }}
                          </th>
                          <th class="text-left">
                            Overtime<br /><br />{{ totalTimes[2] }}
                          </th>
                          <th class="text-left">Overtime paid</th>
                          <th class="text-left">
                            Bonus hours<br /><br />{{ totalTimes["bonus"] }}
                          </th>
                          <th class="text-left">Bonus hours paid</th>
                          <th class="text-left">
                            Project hours<br /><br />{{ totalTimes["project"] }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(day, index) in selectedDays" :key="day">
                          <td>
                            <b>{{ day }}</b>
                          </td>
                          <td>
                            <v-text-field
                              type="number"
                              min="0"
                              v-model="formModel.times[index * 4].time"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              type="number"
                              min="0"
                              v-model="formModel.times[index * 4 + 2].time"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              type="number"
                              min="0"
                              v-model="formModel.times[index * 4 + 3].time"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              :disabled="formModel.times[index * 4 + 1].paid"
                              type="number"
                              min="0"
                              v-model="formModel.times[index * 4 + 1].time"
                              @input="
                                formModel.times[index * 4 + 1].dirty = true
                              "
                            ></v-text-field>
                          </td>
                          <td>
                            <v-btn
                              v-if="
                                !formModel.times[index * 4 + 1].paid &&
                                formModel.times[index * 4 + 1].time > 0
                              "
                              color="primary mr-2"
                              elevation="2"
                              @click="
                                handlePaid(formModel.times[index * 4 + 1])
                              "
                              :disabled="formModel.times[index * 4 + 1].dirty"
                              >Paid</v-btn
                            >
                            <span v-else>
                              {{ formModel.times[index * 4 + 1].paid }}
                            </span>
                          </td>

                          <td>{{ formModel.bonusTimes[index].time }}</td>
                          <td>
                            <v-btn
                              v-if="
                                !formModel.bonusTimes[index].paid &&
                                formModel.bonusTimes[index].project_member_id
                              "
                              color="primary mr-2"
                              elevation="2"
                              @click="
                                handleBonusPaid(formModel.bonusTimes[index])
                              "
                              >Paid</v-btn
                            >
                            {{ formModel.bonusTimes[index].paid }}
                          </td>
                          <td>
                            {{ getProjectTime(day) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$emit('closeModalForm')">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="handleSaveModalForm"
            v-if="permissionCan('time.create')"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay :value="loader">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { ENDPOINT, PERMISSION_TO } from "./Employees";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import Swal from "sweetalert2";

export const initialFormData = () => ({
  id: null,
  times: [],
  bonusTimes: [],
  projectTimes: [],
});

const TRANSLATED_ATRIBUTES = [];

export default {
  name: "EmployeeMonthlyHoursModal",
  props: ["modalData", "permissions", "employee"],
  mixins: [formModelMixins],
  data() {
    return {
      loader: false,
      menu: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      permissionTo: PERMISSION_TO,
      endPoint: ENDPOINT,

      formValid: false,
      messages: {},
      nameRules: [
        (v) => !!v || "This field is required",
        (v) =>
          (!!v && v.length < 255) || "This field must be max 255 characters",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      selectedDate: null,
      dates: [],
      selectedDays: [],
    };
  },
  computed: {
    ...mapGetters(["statuses"]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    initialFormData() {
      return initialFormData;
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    formattedDates() {
      if (this.dates.length == 2) {
        return this.dates[0] + " - " + this.dates[1];
      }
      return this.dates;
    },

    totalTimes() {
      let total = [];
      total[0] =
        total[1] =
        total[2] =
        total[3] =
        total[4] =
        total["bonus"] =
        total["project"] =
          0;
      this.formModel.times.forEach((time) => {
        total[time.type] += +time.time;
      });
      this.formModel.bonusTimes.forEach((time) => {
        total["bonus"] += +time.time;
      });

      this.formModel.projectTimes.forEach((time) => {
        total["project"] = total["project"] + time.time / 3600;
      });

      return total;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        if (value.editedId) {
          this.loadFormData(value);
        }
      },
    },

    dates: function (val) {
      if (val.length == 2 && this.modalData.editedId) {
        this.formModel.times = [];
        this.formModel.bonusTimes = [];
        this.loadFormData(this.modalData);
      }
    },
  },
  methods: {
    // ...mapActions([FETCH_CURRENCY]),
    loadFormData(value) {
      this.getDays(this.dates[0], this.dates[1]).then(() => {
        ApiService.get(
          `humanResources/employee/${value.editedId}/time/${this.dates[0]}/${this.dates[1]}`
        )
          .then(({ data }) => {
            this.formModel.projectTimes = data.projectTimes;
            data.employeeTimes.forEach((time) => {
              let item = this.formModel.times.find(
                (t) => t.date == time.date && t.type == time.type
              );
              if (item) {
                item.id = time.id;
                item.time = time.time / 3600;
                item.paid = time.paid;
                item.dirty = false;
                item.amount = time.amount;
              }
            });

            data.bonusTimes.forEach((time) => {
              let item = this.formModel.bonusTimes.find(
                (t) => t.date == time.date
              );
              if (item) {
                item.project_member_id = time.project_member_id;
                item.time = time.bonus_time / 3600;
                item.paid = time.paid;
                item.dirty = false;
              }
            });
          })
          .catch((error) => {
            console.log("Error!: ", error);
          });
      });
    },

    handleSaveModalForm() {
      let model = Object.assign({}, this.formModel);

      model.times = model.times.map((time) => {
        if (time.time > 0) {
          time.time = time.time * 3600;
        }
        return time;
      });

      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        this.loader = true;

        ApiService.post(
          `humanResources/employee/${this.modalData.editedId}/time`,
          model
        )
          .then(() => {
            this.resetDate();
            this.loadFormData(this.modalData);
            // this.$emit("saveModalForm");
          })
          .catch((error) => {
            if (error.response) {
              let errors = error.response.data;
              if (errors) {
                for (let field in errors) {
                  this.setError(field, errors[field][0]);
                }
              }
            }
          })
          .finally(() => {
            this.loader = false;
          });
      }
    },

    getDays(startDate, endDate) {
      return new Promise((resolve) => {
        this.selectedDays = this.getDatesDiff(startDate, endDate);
        this.selectedDays.forEach((day) => {
          for (let i = 1; i < 5; i++) {
            let dayObject = {
              date: day,
              type: i,
              paid: null,
              time: 0,
              dirty: false,
              amount: 0,
            };
            this.formModel.times.push(dayObject);
          }
          this.formModel.bonusTimes.push({
            date: day,
            paid: null,
            time: 0,
          });
        });
        resolve();
      });
    },

    getDatesDiff(start_date, end_date, date_format = "YYYY-MM-DD") {
      const getDateAsArray = (date) => {
        return this.moment(date.split(/\D+/), date_format);
      };
      const diff =
        getDateAsArray(end_date).diff(getDateAsArray(start_date), "days") + 1;
      const dates = [];
      for (let i = 0; i < diff; i++) {
        const nextDate = getDateAsArray(start_date).add(i, "day");
        // const isWeekEndDay = nextDate.isoWeekday() > 5;
        // if (!isWeekEndDay) dates.push(nextDate.format(date_format));
        dates.push(nextDate.format(date_format));
      }

      return dates;
    },

    resetFormData() {
      this.formModel = Object.assign({}, initialFormData());
      this.modalData.editedId = null;
      this.selectedDays = [];
      this.resetDate();
    },

    resetDate() {
      var date = new Date();

      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
        .toLocaleDateString("hu-HU", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replaceAll(". ", "-")
        .replaceAll(".", "");

      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        .toLocaleDateString("hu-HU", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replaceAll(". ", "-")
        .replaceAll(".", "");
      this.dates = [];
      this.dates.push(firstDay, lastDay);
      this.selectedDays = [];
    },

    handleCloseModalForm() {
      this.resetFormData();
      this.$emit("closeModalForm");
    },

    handlePaid(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, paid!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Paid!", "Overtime hours was paid.", "success");
          ApiService.get(`humanResources/employeeTime/${item.id}/pay`).then(
            ({ data }) => {
              item.paid = data;
            }
          );
        }
      });
    },

    handleBonusPaid(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, paid!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.get(
            `manufacture/projectMember/${item.project_member_id}/pay`
          ).then(({ data }) => {
            item.paid = data;
          });
          Swal.fire("Paid!", "Bonus hours was paid.", "success");
        }
      });
    },

    getProjectTime(day) {
      let date = this.formModel.projectTimes.find((date) => {
        return date.date == day;
      });

      if (date) {
        return date.time / 3600;
      }

      return 0;
    },
  },

  mounted() {
    this.resetDate();
    // this.getDatesDiff(this.dates[0], this.dates[1]);
  },
};
</script>
